"use client";
import { scan } from "react-scan";
import React, { useState } from "react";
import Button from "@components/library/Button";
import { StatusOnlineIcon } from "@heroicons/react/solid";

export const ReactScan = () => {
  const isDev = process.env.NODE_ENV === "development";
  const [enabled, setEnabled] = useState(false);
  if (!isDev) {
    return null;
  }

  if (typeof window !== "undefined") {
    scan({
      enabled: enabled,
      log: true, // logs render info to console (default: false)
    });
  }
  if (enabled) {
    return null;
  }
  return (
    <div className="fixed bottom-20 right-20 z-50 ">
      {" "}
      {/* Media breakpoint */}
      <div className="flex items-center gap-12 rounded bg-black/80 px-4 py-1 font-mono text-12-12  text-white">
        <span className="sm:hidden">xs</span>
        <span className="hidden sm:inline md:hidden">sm</span>
        <span className="hidden md:inline lg:hidden">md</span>
        <span className="hidden lg:inline xl:hidden">lg</span>
        <span className="hidden xl:inline 2xl:hidden">xl</span>
        <span className="hidden 2xl:inline">2xl</span>
        <Button
          IconLeft={StatusOnlineIcon}
          shade="black"
          styling="solid"
          size={24}
          onClick={() => setEnabled(!enabled)}
        >
          Scan
        </Button>
      </div>
    </div>
  );
};
