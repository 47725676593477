// Analytics

// Our analytics strategy is the following.
//
// Most analytics events are sent from the backend to Segment. We do this
// on the backend to make it reliable without risk of losing events due to
// ad blockers etc.
//
// On the frontend we still have some analytics so that we can track page views
// and other events that happen only the client, or events that happen when the
// user does not have an account. The client-side libraries will get blocked by
// ad-blockers (losing maybe 30% of events) so we'll try to proxy the requests
// where possible. The benefit of client-side tracking is that you get a lot of
// benefits for free. They'll automatically create anonymous IDs for un-identified users,
// track their path through the app and then join them to a user
// when they sign in. They may also automatically capture other client characteristics
// like user journey replays, console logs, all clicks etc. (depending on the library)
//
// We're primarily using Segment which feeds to Mixpanel (and elsewhere) but our plan
// at one point was to use PostHog for everything. We may abandon this plan, primarily
// because of perceived product quality with PH (not super easy to use/fully-featured
//  product analytics, and even more half-baked other features like flags, and replays).
// We use the PostHog library directly because that had many client-side benefits not
// available via Segment like event-autocapture. This may change still.
//
// So:
// - Backend sends all meaningful events to Segment
// - Frontend tracks pageviews, anonymous users, and interesting client-only events
// - Frontend calls identify() to join the anon user to their account
// - Backend calls identify() to supply the user traits and join them to their organization
// - Frontend calls reset() to clear the cookies when the user logs out
// How to use
// ==========
// For the client only events, use the `analytics` singleton.
//
//  import { analytics } from "lib/analytics";
//
// analytics.track("Button Clicked", {
//   location: "organization-billing-plan-comparison",
//   purpose: "sales",
//   url: SALES_CALL_LINK,
// });

import { AnalyticsBrowser } from "@segment/analytics-next";
import { useRouter } from "next/router";
import posthog from "posthog-js";
import { useEffect } from "react";

declare global {
  interface Window {
    Cohere?: {
      init: () => void;
      stop: () => void;
      [key: string]: any;
    };
  }
}

/*
  Our analytics events are primarily sent in the backend.
  Only use client-side events for the few events that are interesting and
  happen client-side only.

  Make sure that the same naming convention is followed. 
  Generally Noun past-tense Verb in title case.
  See `AnalyticsEvent` enum in backend in 
  `src/external/app/routers/analytics/dependency.py`
  
  Keep the Nouns and Noun-Verbs to a minimum. Use a semantic group 
  if available and fall back to the literal objects (e.g. Button) if 
  that has its challenges.

  Some of the best guidance is at June e.g.:
  https://help.june.so/en/articles/6817174-new-event-vs-new-property
*/

// Avoid using Link Clicked - legacy. Use Button Clicked instead.
export type AnalyticsEvent = "Button Clicked" | "Link Clicked";

// Define allowed locations as string literals
type Location =
  | "playground"
  | "playground-history"
  | "log-export"
  | "organization-creation-form"
  | "organization-billing-plan-comparison"
  | "upsell-banner"
  | "debug"
  | "onboarding";

// Specific properties for each event can extend a base interface if they share common properties
interface EventPropertiesBase {
  location: Location;
  purpose?: string; // Keep this hyphenated and short e.g. "sales-call"
  url?: string; // Not sure why this is needed but we have it on some events, would have though autocaptured.
  [key: string]: any; // Allow any other properties
}

interface EventPropertiesMapping {
  "Button Clicked": EventPropertiesBase; // Add specific properties if needed
  "Link Clicked": EventPropertiesBase; // Customize as necessary
}

// Constants to follow our object-action-properties naming convention
// https://humanloop.notion.site/Analytics-Naming-Convention-c3409da767c64a4497d6eb213e3a446e

const SEGMENT_KEY = process.env.NEXT_PUBLIC_SEGMENT_KEY;
const POSTHOG_KEY = process.env.NEXT_PUBLIC_POSTHOG_KEY;
const NODE_ENV = process.env.NODE_ENV;
const LOGGING = false;

// Apply the cohere block based on the privacy level
export type PrivacyLevel = "high" | "moderate" | "low";
export const PRIVACY_CLASSNAMES: Record<PrivacyLevel, string> = {
  high: "cohere-block",
  moderate: "cohere-block",
  low: "",
};

const segment = AnalyticsBrowser.load(
  {
    writeKey: SEGMENT_KEY || "",
    // Proxy the CDN
    // GET http://cdn.segment.com/v1/projects/<writekey>/settings -> /cdn/v1/project/<writekey>/settings
    // GET https://cdn.segment.com/next-integrations/actions/...js -> /cdn/next-integrations/actions/...js
    cdnURL: "/cdn",
  },
  {
    integrations: {
      "Segment.io": {
        // https://api.segment.io/v1/t -> https://app.humanloop.com/edn/t
        apiHost: "app.humanloop.com/edn",
        protocol: "https",
      },
    },
    // Don't believe this does anything for our set up but just in case.
    obfuscate: true,
  },
);

export const useAnalytics = () => {
  const router = useRouter();
  const trackingDisabled = isTrackingDisabled();

  // Initialise PostHog
  useEffect(() => {
    if (trackingDisabled) {
      return;
    }
    if (POSTHOG_KEY) {
      posthog.init(POSTHOG_KEY, {
        // humanloop.com/ingest is a nextjs rewrite to app.posthog.com, acting
        // as a proxy so we don't lose data to ad blockers.
        api_host: "/ingest",
        opt_in_site_apps: true,
        person_profiles: "identified_only",
      });
      posthog.debug(false);
    } else {
      console.log("PostHog key not found. Not enabled.");
    }
  }, [trackingDisabled]);

  // Track page views
  useEffect(() => {
    if (trackingDisabled) {
      return;
    }
    // Track the initial page load
    analytics.page();

    // Track page views
    const handleRouteChange = () => analytics.page();

    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events, trackingDisabled]);
};

export const DISABLE_TRACKING_KEY = "disable-tracking";

const setDisableTrackingFlag = () => {
  // Setting this flag will disable all frontend tracking.
  if (typeof window !== "undefined") {
    window.localStorage.setItem(DISABLE_TRACKING_KEY, "true");
  }
};

const clearDisableTrackingFlag = () => {
  if (typeof window !== "undefined") {
    window.localStorage.removeItem(DISABLE_TRACKING_KEY);
  }
};

const isTrackingDisabled = () => {
  if (typeof window !== "undefined") {
    return window.localStorage.getItem(DISABLE_TRACKING_KEY);
  }
};

const ORGANIZATIONS_WITH_DISABLED_AUTOCAPTURE = new Set([
  "org_GfMlEP8DjPh9zpHMPVT3B", // Athena
]);

/**
 * The context object that is attached to every event.
 *
 * This expands on what Segment will add by default, with
 * the context of what application the event is coming from.
 *
 * This should play well with the context objects we send in
 * `src/external/app/routers/analytics/dependency.py`
 *
 * See: https://segment.com/docs/connections/spec/common/#context
 */
const appContext = {
  app: {
    name: "hl-alpha-frontend",
  },
};

const identify = (userId?: string, traits?: any) => {
  if (isTrackingDisabled()) {
    return;
  }
  LOGGING && console.info("identify", userId, traits);
  // Segment can handle undefined userId if we just wish to add traits (e.g. email from a form)
  segment.identify(userId, traits, { context: appContext });
  // Posthog cannot handle undefined userId, but does have `setPersonProperties`.
  if (!userId) {
    posthog.setPersonProperties(traits);
  } else {
    posthog.identify(userId, traits);
  }
};

const page = (
  // Page call will include other helpful properties automatically like url, title, referrer.
  category?: string,
  name?: string,
  properties?: Record<string, any>,
  organizationId?: string,
) => {
  if (isTrackingDisabled()) {
    return;
  }
  // TODO: Would be nice to auto-add the page name.
  properties = { ...properties, organization_id: organizationId };
  const context = { ...appContext, groupId: organizationId };
  LOGGING && console.info("page", category, name, properties);
  segment.page(category, name, properties, { context });
  posthog.capture("$pageview");
};

const track = (event: AnalyticsEvent, properties?: EventPropertiesMapping[AnalyticsEvent], organizationId?: string) => {
  if (isTrackingDisabled()) {
    return;
  }
  const propertiesWithOrg = { ...properties, organization_id: organizationId };
  const context = { ...appContext, groupId: organizationId };
  LOGGING && console.info("track", event, propertiesWithOrg, context);
  segment.track(event, propertiesWithOrg, { context });
  posthog.capture(event, { ...propertiesWithOrg, ...context });
};

export const reset = () => {
  LOGGING && console.info("reset");
  segment.reset();
  // This always complains with error
  // https://posthog.com/docs/libraries/react#typeerror-cannot-read-properties-of-undefined
  // if not optionally chained. Indicating its not been initialized here... which I don't
  // understand, but haven't seen any consequences.
  posthog?.reset();
};

/**
 * Singleton class for analytics.
 *
 * This primarily exists to store the organizationId, so that it can be
 * inserted into all events so that we can group by organization.
 */
class Analytics {
  organizationId?: string;

  /**
   * Identify a user
   *
   * This should be called when the user logs in, and when the user signs up.
   *
   * This is also called on the backend, but we do it on the client to
   * join the anonymous user to their account. Unless there's frontend only
   * traits, we also don't need to worry about adding the traits here.
   *
   * @param userId `usr_...`
   * @param traits arbitrary key-value pairs that are attached to the user.
   *  I believe most destinations upsert on this collection.
   */
  public identify = (userId?: string, traits?: any, organizationId?: string) => {
    if (!!organizationId) {
      this.organizationId = organizationId;
      if (ORGANIZATIONS_WITH_DISABLED_AUTOCAPTURE.has(organizationId)) {
        posthog.set_config({ autocapture: false });
        // Turning off recording of Athena in Cohere too as low signal.
        if (typeof window !== "undefined" && window.Cohere) {
          window.Cohere.stop();
          delete window.Cohere;
        }
      }
    }
    identify(userId, traits);
  };

  /**
   * Track a page view
   *
   * The page call will automatically track the path, title, url.
   * Can add in optional properties or override the others.
   *
   * See https://segment.com/docs/connections/spec/page/
   *
   * @param category (optional) – Type of page. I don't think we'll want this.
   *  More appropriate for a blog or ecommerce.
   * @param name (optional) - A canonical name for the page.
   * @param properties (optional)
   */
  public page = (category?: string, name?: string, properties?: Record<string, any>) => {
    page(category, name, properties, this.organizationId);
  };

  /**
   * Track an event
   *
   * Only track events that are client-side only and interesting for product analytics.
   * Most events should be sent from the backend.
   *
   * @param event An AnalyticsEvent name that follows our naming convention.
   * @param properties - All the properties that are relevant to the event.
   *
   */
  public track = (event: AnalyticsEvent, properties?: EventPropertiesMapping[AnalyticsEvent]) => {
    track(event, properties, this.organizationId);
  };

  /**
   * Reset the analytics state
   *
   * This should be called when the user logs out.
   */
  reset = () => {
    this.organizationId = undefined;
    reset();
  };

  /**
   * Disable tracking
   *
   * This should be called when the user opts out of tracking.
   */
  disableTracking = () => {
    setDisableTrackingFlag();
    this.organizationId = undefined;
    reset();
  };

  /**
   * Enable tracking
   *
   * This should be called when the user opts in to tracking.
   * This is the default state.
   */
  enableTracking = () => {
    clearDisableTrackingFlag();
  };

  /**
   * Check if tracking is disabled
   *
   * This should be called to check if tracking is disabled.
   *
   * @returns true if tracking is disabled, false otherwise
   */
  trackingDisabled = () => {
    return isTrackingDisabled();
  };
}

export const analytics = new Analytics();
